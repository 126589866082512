import React, { useMemo, useState } from "react";
import AssetLibrary from "./AssetLibrary/AssetLibrary";
import AssetUploader from "./AssetUploader/AssetUploader";
import UploadeWarningModal from "./AssetUploader/UploadWarningModal";
import { fileListObject, uploadedFileObject, IAssetUploaderState } from "./interfaces/uploaderInterfaces";
import { ReactComponent as UploadPlusIcon } from "../../assets/icons/AssetManager/add-gray-version-icon.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/Common/remove-tag-icon.svg";
import * as AssetManagerTypes from "./AssetManager.types";
import {
  usePageAssociation,
  useAssetManagerState,
  useAssetManagerDispatch,
  useAssetManager,
} from "./AssetManagerContext/AssetManagerProvider";
import InputPicker from "rsuite/InputPicker";
import "../Modals/CognitiveServices/inputPicker.css";
import { connect } from "react-redux";
import AssetMetadataEditor from "./AssetLibraryMetadataEditor/AssetMetadataEditor";
import IAsset from "../../models/IAsset";
import { AssetContext } from "../../routes/builderContexts";
import { useObjectsDispatch, useObjectsState, ObjectActionsType } from "../../contexts/ObjectsProvider";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import { getVariablesFromSmartObject } from "../../lib/SmartObject";
import { useMetaVariableStore } from "../../lib/SmartObject/store";
import {
  getSmartObjectName,
  getSmartObjectViewBox,
  getSmartObjectViewBoxMetaVariableKeys,
} from "../../lib/SmartObject/utils";
import { a } from "react-spring";
import { SmartObject } from "../../types";
import { audioBlobToApi } from "../../panels/TimelinePanel/helpers";
import { EInteractiveAudioType } from "../../models/IInteractiveAudio";
import { useAudioManagerStore } from "../../contexts/PageAudioManager";
import { InsertMode, useAudioEffectStore } from "../../panels/TimelinePanel/Right/AudioEffectRow/store";
let resolve: any;
function AssetManagerWrapper(props: any) {
  const [assetManagerMode, setAssetManagerMode] = useState<AssetManagerTypes.AssetManagerMode>("Library");
  const [uploadWarningShown, setUploadWarningShown] = useState<boolean>(false);
  const [uploadsProcessing, setUploadsProcessing] = useState<boolean>(false);
  // const [temporaryPagination, setTemporaryPagination] = useState(0)
  const assetContext = React.useContext(AssetContext);
  const assetManagerState = useAssetManager();
  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();

  const [saveAssets, setSaveAssets] = useState<boolean>(false);
  const [movingToLibraryFromUploader] = useState<boolean>(false);
  const [safeToCloseUploader, setSafeToCloseUploader] = useState<boolean>(false);
  const [saveAssetErrorMessage, setSaveAssetErrorMessage] = useState<string>("");
  const [closeCLick, setCloseClick] = useState(0);
  const pageAssociation = usePageAssociation(props.IGAAssetType);
  const state = useAssetManagerState();
  const dispatch = useAssetManagerDispatch();
  /***************************************************************UPLOADER STATE************************************************************************************************* */

  const [fileString, setFileString] = useState<string>("");
  const [filesUploaded, setFilesUploaded] = useState<number>(0);
  const [selectedId, setSelectedID] = useState<number>(-1);
  const [ataIds, setAtaIds] = useState<number[]>([]);
  const [newFiles, setNewFiles] = useState<fileListObject[]>([]); //needs to flag for loading
  const [uploadedAssets, setUploadedAssets] = useState<uploadedFileObject[]>([]);
  const [pageList, setPageList] = useState<number[]>([0]);

  const [versionName, setVersionName] = useState<string>("");
  const [canClickSave, setCanClickSave] = useState<boolean>(false);
  const [selectedLMS, setSelectedLMS] = useState<string>(props.lessonData?.lessonMetaData?.lmsLessonKey);
  const { objectList, selectedObjects } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const addNewMetaVariable = useMetaVariableStore((s) => s.addNewMetaVariable);
  const clearObjectMetaVariables = useMetaVariableStore((s) => s.clearObjectMetaVariables);
  const loadEffectAudio = useAudioManagerStore((s) => s.loadEffectAudio);
  const selectedAudio = useAudioManagerStore((s) => s.getSelectedAudio());
  const insertMode = useAudioEffectStore((s) => s.insertMode);

  const showLibrary = assetManagerMode === "Archives" || assetManagerMode === "Library";
  const uploaderState: IAssetUploaderState = {
    fileString,
    setFileString,
    filesUploaded,
    setFilesUploaded,
    selectedId,
    setSelectedID,
    ataIds,
    setAtaIds,
    newFiles,
    setNewFiles,
    uploadedAssets,
    setUploadedAssets,
    pageList,
    setPageList,
    versionName,
    setVersionName,
    canClickSave,
    setCanClickSave,
  };

  /******************************************************************************************************************************************************************************************* */

  async function handleCancel(safeToCancel?: boolean) {
    if (!safeToCancel) {
      const closeConfirm = await UploadeWarningModal.show({
        ...props,
        setUploadWarningShown: setUploadWarningShown,
      });
      if (closeConfirm) {
        props.setAssetManagerController({ isOpen: false, mode: "" });
      } else {
        setUploadWarningShown(false);
      }
    } else {
      props.setAssetManagerController((prev: any) => ({
        ...prev,
        isOpen: false,
      }));
    }
  }

  const addToPageClick = async (e: any) => {
    if (props.assetManagerController.mode === "ImagesOnly" && props.IGAAssetType === 4) {
      props.getIGAImage(pageAssociation);
      handleCancel(true);
    } else if (props.assetManagerController.mode !== "References") {
      if (state.currentSelectedAsset.assetId !== -1) {
        pageAssociation();
        handleCancel(true);
      }
      if (props.assetManagerController.mode === "ImagesOnly") {
        const selectedObject = selectedObjects[0];
        const imagePath = assetManagerState.currentSelectedAsset.blobPath;
        const assetId = assetManagerState.currentSelectedAsset.assetId;
        const image = new Image();
        image.src = "/api/Asset/" + imagePath;
        image.onload = (e) => {
          if (!e.currentTarget) return;
          if (!viewportDOMElementHeight || !viewportDOMElementWidth) return;
          let imageWidth = e.currentTarget.naturalWidth;
          let imageHeight = e.currentTarget.naturalHeight;

          const imageAspectRatio = imageWidth / imageHeight;

          if (imageHeight > viewportDOMElementHeight) {
            imageHeight = viewportDOMElementHeight;
            imageWidth = imageHeight * imageAspectRatio;
          } else if (imageWidth > viewportDOMElementWidth) {
            imageWidth = viewportDOMElementWidth;
            imageHeight = imageWidth / imageAspectRatio;
          }
          const imagePercentWidth = (imageWidth / viewportDOMElementWidth) * 100;
          const imagePercentHeight = (imageHeight / viewportDOMElementHeight) * 100;

          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT,
            payload: {
              objectId: selectedObject.objectId,
              object: {
                width: imagePercentWidth,
                height: imagePercentHeight,
                natural: {
                  width: e.currentTarget.naturalWidth,
                  height: e.currentTarget.naturalHeight,
                },
              },
            },
          });
        };
        objectsDispatch({
          type: ObjectActionsType.UPDATE_IMAGE_ASSET,
          payload: { objectId: selectedObject.objectId, imagePath, assetVersionId: assetId },
        });
      }
      if (props.assetManagerController.mode === "VideosOnly") {
        const selectedObject = selectedObjects[0];
        const path = assetManagerState.currentSelectedAsset.blobPath;
        const assetId = assetManagerState.currentSelectedAsset.assetId;
        objectsDispatch({
          type: ObjectActionsType.UPDATE_VIDEO_ASSET,
          payload: { objectId: selectedObject.objectId, blobUrl: path, assetVersionId: assetId },
        });
      }
      if (props.assetManagerController.mode === "SCORM") {
        const selectedObject = selectedObjects[0];
        const path = assetManagerState.currentSelectedAsset.blobPath;
        const assetId = assetManagerState.currentSelectedAsset.assetId;
        objectsDispatch({
          type: ObjectActionsType.UPDATE_SCORM_ASSET,
          payload: { objectId: selectedObject.objectId, blobUrl: path, assetVersionId: assetId },
        });
      }
      if (props.assetManagerController.mode === "SmartObject") {
        const selectedObject = selectedObjects[0] as SmartObject;
        const selectedAsset = assetManagerState.currentSelectedAsset;
        const { blobPath } = selectedAsset;
        const folder = blobPath.split("/").pop();
        /**
         * This might need to be improved/changed later
         */
        const smartObjectPath = "/api/Asset/" + blobPath + "/smartobject.json";
        const res = await fetch(smartObjectPath);
        const smartObject = await res.json();

        /**
         * If the selected object has an `elementList`
         * is because we are editing an existing smart object
         * so we have to clear the current meta variables
         * for that object before adding the new ones
         */
        if (selectedObject.elementList) {
          clearObjectMetaVariables(selectedObject.objectId);
        }

        const name = getSmartObjectName(smartObject[0]);
        const prettyBlobPath = folder ? `${folder}.svg` : `${name}.svg`;
        const { variables, variablesData, fonts } = getVariablesFromSmartObject(
          smartObject[0],
          selectedObject.objectId,
        );
        const viewBox = getSmartObjectViewBox(smartObject[0]);
        if (!viewBox) return; // Better validation needed
        if (!viewportDOMElementWidth || !viewportDOMElementHeight) return;
        const viewBoxWidth = viewBox[2];
        const viewBoxHeight = viewBox[3];
        const aspectRatio = viewBoxWidth / viewBoxHeight;
        const isPortrait = aspectRatio < 1;
        let newWidth = viewBoxWidth;
        let newHeight = viewBoxHeight;
        const desiredStartingWidthPercent = 60;
        const desiredStartingHeightPercent = 60;
        const desiredStartingWidth = (viewportDOMElementWidth * desiredStartingWidthPercent) / 100;
        const desiredStartingHeight = (viewportDOMElementHeight * desiredStartingHeightPercent) / 100;

        if (isPortrait) {
          // set the desired height to the desired starting height
          newHeight = desiredStartingHeight;
          // scale the width proportionally
          newWidth = (newHeight / viewBoxHeight) * viewBoxWidth;
        } else {
          // set the desired width to the desired starting width
          newWidth = desiredStartingWidth;
          // scale the height proportionally
          newHeight = (newWidth / viewBoxWidth) * viewBoxHeight;
        }

        // now that we have the properly adjusted pixel values we will convert them to percentages
        // we want to make the large side 60% of the screen and the other side will be scaled proportionally
        const widthPercent = (newWidth / viewportDOMElementWidth) * 100;
        const heightPercent = (newHeight / viewportDOMElementHeight) * 100;

        const viewBoxX = viewBox[0];
        const viewBoxY = viewBox[1];

        const { viewBoxHeightKey, viewBoxWidthKey, viewBoxXKey, viewBoxYKey } = getSmartObjectViewBoxMetaVariableKeys(
          selectedObject.objectId,
        );

        addNewMetaVariable({
          key: viewBoxWidthKey,
          value: viewBoxWidth,
          type: "number",
          choices: [],
          name: viewBoxWidthKey,
        });
        addNewMetaVariable({
          key: viewBoxHeightKey,
          value: viewBoxHeight,
          type: "number",
          choices: [],
          name: viewBoxHeightKey,
        });
        addNewMetaVariable({
          key: viewBoxXKey,
          value: viewBoxX,
          type: "number",
          choices: [],
          name: viewBoxXKey,
        });
        addNewMetaVariable({
          key: viewBoxYKey,
          value: viewBoxY,
          type: "number",
          choices: [],
          name: viewBoxYKey,
        });

        Object.keys(variables).forEach((key) => {
          addNewMetaVariable({
            ...variablesData[key],
            key,
            value: variables[key],
          });
        });

        if (fonts) {
          Object.keys(fonts).forEach((key) => {
            addNewMetaVariable({
              ...fonts[key],
              key,
              value: fonts[key],
            });
          });
        }

        const payload = {
          objectId: selectedObject.objectId,
          object: {
            blobPath: prettyBlobPath,
            displayName: name ? name : selectedObject.displayName,
            elementList: smartObject,
            width: widthPercent,
            height: heightPercent,
          },
        };

        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT,
          payload,
        });
      }

      if (props.assetManagerController.mode === "AudioEffectOnly") {
        try {
          const start = insertMode === InsertMode.BEFORE && selectedAudio ? selectedAudio.start : -1;

          const selectedAsset = assetManagerState.currentSelectedAsset;
          const blob = await fetch(`/api/Asset/${selectedAsset.blobPath}`);
          const mp3 = await blob.blob();
          const url = URL.createObjectURL(mp3);
          const audio = new Audio(url);
          audio.addEventListener(
            "loadeddata",
            async () => {
              const duration = Number(audio.duration.toFixed(2));
              const end = start + duration;
              await loadEffectAudio(selectedAsset.assetId.toString(), audioBlobToApi(selectedAsset.blobPath), {
                start,
                end,
                type: EInteractiveAudioType.EFFECT,
                duration,
                title: `Audio Effect ${selectedAsset.assetId}`,
              });
            },
            { once: true },
          );
        } catch (error) {
          console.error("error adding audio effect", error);
        }
      }
    }

    if (props.assetManagerController.mode === "PanoramicOnly") {
      const selectedObject = selectedObjects[0];
      const selectedAsset = assetManagerState.currentSelectedAsset;
      const { assetId, blobPath, panoramicImages } = selectedAsset;
      if (!panoramicImages) return; // add better validation ?

      const payload = {
        objectId: selectedObject.objectId,
        object: {
          assetVersionId: assetId,
          assetBlobPath: blobPath,
          name: selectedAsset.name,
          pType: "cubemap",
          cubeMap: [
            {
              type: "front",
              path: panoramicImages.frontImage,
            },
            {
              type: "back",
              path: panoramicImages.backImage,
            },
            {
              type: "left",
              path: panoramicImages.leftImage,
            },
            {
              type: "right",
              path: panoramicImages.rightImage,
            },
            {
              type: "top",
              path: panoramicImages.topImage,
            },
            {
              type: "bottom",
              path: panoramicImages.bottomImage,
            },
          ],
        },
      };

      objectsDispatch({
        type: ObjectActionsType.UPDATE_OBJECT,
        payload,
      });
    } else {
      if (state.currentSelectedAsset.assetId !== -1) {
        resolve(state.currentSelectedAsset);
        props.setAssetManagerController({ isOpen: false, mode: "" });
      }
    }
  };

  const saveAssetClick = (e: any) => {
    setSaveAssets(true);
  };
  const setAssets = (assets: IAsset[]) => {
    dispatch({
      type: "SET_RETRIEVED_ASSETS",
      payload: assets,
    });
  };
  const moveToLibrary = async (e: any) => {
    if (assetManagerMode === "Uploader") {
      setAssetManagerMode("Library");
      setCloseClick(0);
    } else {
      setAssetManagerMode("Library");
      e.stopPropagation();
    }
  };

  function handleLMSChange(mySelectedLMS: string) {
    setSelectedLMS(mySelectedLMS);
  }
  const allowLibraryItem = props.isCpatUser && props.lmsKey === selectedLMS; // don't allow Share checkbox if not CPaT, or uploading to different LMS than current logged in

  const metadataEditor = useMemo(() => {
    return (
      <AssetMetadataEditor
        selectedAsset={state.currentSelectedAsset}
        assetsAreLoading={state.assetsAreLoading}
        isSameLMS={props.lmsKey === props.lessonData?.lessonMetaData?.lmsLessonKey}
        isCpatUser={props.isCpatUser}
        allowLibraryItem={allowLibraryItem}
        setAssets={setAssets}
        currentAssets={state.retrievedData}
        featuresToRender={props.featuresToRender}
      />
    );
  }, [state.currentSelectedAsset, state.assetsAreLoading]);

  return (
    <div
      className="assetManager-backdrop"
      onPointerDown={() => {
        newFiles.length > 0 ? handleCancel(false) : handleCancel(true);
      }}
      data-backdrop="backdrop"
    >
      <div
        className="assetManager-content-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="assetManager-title">
          <p>Asset Library Manager</p>
          <div
            onClick={() => {
              newFiles.length > 0 ? handleCancel(false) : handleCancel(true);
            }}
          >
            <ExitIcon />
          </div>
        </div>
        <div
          className="assetManager-modal-body"
          style={{
            gridTemplateColumns: showLibrary ? "5fr 2fr" : "1fr",
          }}
        >
          <div className="assetManager-modal-body-left">
            <p className="assetManager-infoText">Select asset from the library or add a new one</p>

            <div className="assetManager-library-upload-choose">
              <div className="upload-choose-buttons-container">
                <div
                  className={`assetManager-library-button${
                    assetManagerMode === "Library" ? " manager-selected-mode" : ""
                  }`}
                  onClick={moveToLibrary}
                >
                  <p>Library</p>
                </div>
                {props.featuresToRender.assetArchiving && (
                  <div
                    className={`assetManager-library-button${
                      assetManagerMode === "Archives" ? " manager-selected-mode" : ""
                    }`}
                    onClick={() => setAssetManagerMode("Archives")}
                  >
                    <p>Archives</p>
                  </div>
                )}
                <div
                  className={`assetManager-upload-button${
                    assetManagerMode === "Uploader" ? " manager-selected-mode" : ""
                  }`}
                  onClick={(e) => {
                    setAssetManagerMode("Uploader");
                    e.stopPropagation();
                  }}
                >
                  <p>
                    {" "}
                    <UploadPlusIcon /> Upload New
                  </p>
                </div>
              </div>
              {props.isCpatUser && assetManagerMode === "Uploader" && (
                <div className={"  cpat-user-button"}>
                  <span>Select LMS: </span>
                  <InputPicker
                    data={props.lessonData.lmss}
                    appearance="default"
                    placeholder="Select an LMS"
                    value={selectedLMS}
                    valueKey={"lmsKey"}
                    labelKey={"name"}
                    onChange={(e) => {
                      handleLMSChange(e as string);
                    }}
                    size={"sm"}
                    cleanable={false}
                    className={"language-dropdown"} // TODO
                    menuMaxHeight={550}
                  />
                </div>
              )}
            </div>

            {showLibrary && (
              <AssetLibrary
                allAvailableAircraft={props.allAvailableAircraft}
                mode={props.assetManagerController.mode}
                lessonPages={props.lessonPages}
                lessonAssetList={props.lessonAssetList}
                lessonVersionId={props.lessonVersionId}
                currentTab={assetManagerMode}
                excludeArchivedItems={assetManagerMode === "Library" && !state?.assetLibraryFilters[0]?.currentLesson}
              />
            )}

            {assetManagerMode === "Uploader" && (
              <AssetUploader
                saveAssets={saveAssets}
                movingToLibraryFromUploader={movingToLibraryFromUploader}
                setSaveAssets={setSaveAssets}
                tailoredForLms={props.lessonMetaData.customTailoredForLmss}
                isCpatUser={props.isCpatUser}
                lmsKey={props.lmsKey}
                lmsKeyForAssetOwner={props.isCpatUser ? selectedLMS : null}
                uploadWarningShown={uploadWarningShown}
                uploadsProcessing={uploadsProcessing}
                setUploadsProcessing={setUploadsProcessing}
                handleCancel={handleCancel}
                safeToClose={safeToCloseUploader}
                setSafeToClose={setSafeToCloseUploader}
                setSaveAssetErrorMessage={setSaveAssetErrorMessage}
                saveAssetErrorMessage={saveAssetErrorMessage}
                closeClick={closeCLick}
                setCloseClick={setCloseClick}
                uploaderState={uploaderState}
                allowLibraryItem={allowLibraryItem}
              />
            )}

            {uploadWarningShown ? (
              <UploadeWarningModal errorMessage={saveAssetErrorMessage} setUploadWarningShown={setUploadWarningShown} />
            ) : (
              <></>
            )}

            <div className="assetManager-buttons-container">
              <div
                onClick={() => {
                  newFiles.length > 0 ? handleCancel(false) : handleCancel(true);
                }}
              >
                {assetManagerMode !== "Uploader" ? "Close" : "Cancel"}
              </div>
              {assetManagerMode !== "Uploader" ? (
                props.assetManagerController.mode !== "Standalone" &&
                !state.assetsAreLoading && (
                  <div
                    /* archived assets should not be added to the page */
                    className={state.currentSelectedAsset.isArchived ? "disabled" : ""}
                    onClick={(e) => {
                      if (state.currentSelectedAsset.isArchived) return;
                      addToPageClick(e);
                    }}
                  >
                    <p>{props.assetManagerController.mode === "References" ? "Add as Reference" : "Add To Page"}</p>
                  </div>
                )
              ) : (
                <div
                  onClick={
                    assetManagerMode !== "Uploader"
                      ? () => {
                          handleCancel(true);
                        }
                      : canClickSave
                      ? saveAssetClick
                      : undefined
                  }
                  style={{
                    opacity: canClickSave ? 1 : 0.5,
                    pointerEvents: canClickSave ? "all" : "none",
                  }}
                >
                  Save
                </div>
              )}
            </div>
          </div>

          {showLibrary && metadataEditor}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    lmsKey: state.authorizedState.lmsKey,
    isCpatUser: state.authorizedState.isCpatUser,
  };
};

AssetManagerWrapper.show = (open: any, mode: any) => {
  open({ isOpen: true, mode: mode });
  return new Promise((res) => {
    resolve = res;
  });
};

export default connect(mapStateToProps, {})(AssetManagerWrapper);
