import "./ColorPicker.css";
import { useEffect, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import { formatFunctions } from "../functions/WYSIWYGToolbarFunctions";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";

type StandardColorsType = {
  keyValue: "fontColor" | "backgroundColor" | "borderColor" | "annotationFontColor";
};

const StandardColors = ({ keyValue }: StandardColorsType): JSX.Element => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObject = objectsState.selectedObjects[0];
  const standardColors = [
    "#ff0000",
    "#ffff00",
    "#00ff00",
    "#0000ff",
    "#ff00ff",
    "#00ffff",
    "#ffa500",
    "#ffc0cb",
    "#808080",
    "#000000",
    "#ffffff",
    "#d3d3d3",
    "#add8e6",
    "#90ee90",
    "#ffffe0",
    "#ffb6c1",
    "#ffcccb",
    "#e0ffff",
    "#a9a9a9",
    "#00008b",
    "#006400",
    "#8b0000",
    "#9b870c",
    "#8b008b",
    "#008b8b",
  ];

  const handelColorSelect = (color: string, keyValue: string) => {
    if (keyValue === "backgroundColor") {
      objectsDispatch({
        type: ObjectActionsType.SET_BACKGROUND_COLOR,
        payload: {
          color: { hex: color },
          previousColor: {
            hex: objectsState.selectedObjects[0].borderColor ? objectsState.selectedObjects[0].borderColor : "#FFFFFF",
          },
          objectId: selectedObject.objectId,
        },
      });
    } else if (keyValue === "borderColor") {
      objectsDispatch({
        type: ObjectActionsType.SET_BORDER_COLOR,
        payload: {
          color: { hex: color },
          previousColor: {
            hex: objectsState.selectedObjects[0].borderColor ? objectsState.selectedObjects[0].borderColor : "#FFFFFF",
          },
          objectId: selectedObject.objectId,
        },
      });
    }
  };

  return (
    <>
      <div className="object-properties-section-title">Standard Colors</div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "3px", rowGap: "10px" }}>
        <div
          className="colorBox"
          style={{
            backgroundColor: "transparent",
            height: "20px",
            width: "20px",
            border: "1px solid ",
            borderRadius: "2px",
            color: "red",
            fontSize: "large",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
          onClick={() => handelColorSelect("transparent", keyValue)}
        >
          ⊘
        </div>
        {standardColors.map((color, index) => {
          return (
            <div
              key={index}
              className="colorBox"
              style={{
                backgroundColor: color,
                height: "20px",
                width: "20px",
                border: "1px solid ",
                borderRadius: "2px",
              }}
              onClick={() => handelColorSelect(color, keyValue)}
            ></div>
          );
        })}
      </div>
    </>
  );
};

export default StandardColors;
